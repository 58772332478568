import React, { useState, useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  InputGroup,
  Form
} from "react-bootstrap";
import { Paper } from "@material-ui/core";

import DataTable from "react-data-table-component";
import { Search} from "@material-ui/icons";

import useDebounce from "../../../hooks/useDebounce";

import "../../style.css";

const ProductCategoryTab = ({ refresh, userInfo, allCategories }) => {
  const { t } = useTranslation();
  const [multiSelect, setMultiSelect] = React.useState(false);
  const [clearRows, setClearRows] = React.useState(true);
  const [selectedData, setSelectedData] = React.useState([]);

  const [search, setSearch] = React.useState("");

  const debouncedSearch = useDebounce(search, 1000);

  const [filteredCategories, setFilteredCategories] = React.useState([]);


  const handleSelected = (state) => setSelectedData(state.selectedRows);

  React.useEffect(() => {
    const filtered = allCategories.filter((category) =>
      category.name.toLowerCase().includes(debouncedSearch.toLowerCase())
    );
    setFilteredCategories(filtered);
  }, [allCategories, debouncedSearch]);


  const categoryData = (data) => {
    if (!data || !data.length) {
      return [];
    }

    return data.map((item, index) => {
      return {
        id: item.id,
        no: index + 1,
        name: item.name,
        type: item.type,
        description: item.description || "-"
      };
    });
  };

  const customStyles = {
    headRow: {
      style: {
        minHeight: "78px"
      }
    },
    rows: {
      style: {
        minHeight: "70px"
      }
    }
  };

  const columns = [
    {
      name: `${t("no")}`,
      selector: "no",
      sortable: true,
      width: "70px"
    },
    {
      name: `${t("categoryName")}`,
      selector: "name",
      sortable: true
    },
    {
      name: `${t("type")}`,
      selector: "type",
      sortable: true
    },
    {
      name: `${t("description")}`,
      selector: "description",
      sortable: true
    }
  ];

  const [saldo, setSaldo] = useState(0);
  const [maxSaldo, setMaxSaldo] = useState(0);
  const getSaldo = async (businessId) => {
    try {
      const response = await axios.get(`https://api.beetpos.com/api/v1/kios-transaction/check-saldo/${businessId}`);
      const { saldo, max_saldo } = response.data.data;
      setSaldo(saldo);       // Update saldo
      setMaxSaldo(max_saldo); // Update max saldo
    } catch (err) {
      console.error("Error fetching saldo:", err);
      setSaldo(0);           // Default value jika gagal
      setMaxSaldo(0);
    }
  };
  useEffect(() => {
    if (userInfo?.business_id) {
      getSaldo(userInfo.business_id);
    }
  }, [refresh, userInfo]);

  const handleSearch = (e) => setSearch(e.target.value);

  return (
    <Row>
      <Col md={12} style={{ minHeight: "100%" }}>
        <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <h3>{t("productTitle")}</h3>
                  </div>
                </div>

          <div className="filterSection lineBottom">
            <InputGroup className="pb-3">
              <InputGroup.Prepend>
                <InputGroup.Text style={{ background: "transparent" }}>
                  <Search />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                placeholder="Search..."
                value={search}
                onChange={handleSearch}
              />
            </InputGroup>
          </div>

          <DataTable
            noHeader
            pagination
            columns={columns}
            data={categoryData(filteredCategories)}
            style={{ minHeight: "100%" }}
            selectableRows={multiSelect}
            onSelectedRowsChange={handleSelected}
            clearSelectedRows={clearRows}
            noDataComponent={t("thereAreNoRecordsToDisplay")}
            customStyles={customStyles}
          />
        </Paper>
      </Col>
    </Row>
  );
};

export default ProductCategoryTab;
