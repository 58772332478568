import React from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Button,
  Alert,
  Dropdown,
  InputGroup,
  Form
} from "react-bootstrap";
import { Paper } from "@material-ui/core";

import DataTable from "react-data-table-component";
import { Search, MoreHoriz, Delete } from "@material-ui/icons";

import useDebounce from "../../../hooks/useDebounce";

import ConfirmModal from "../../../components/ConfirmModal";
import EditPpobProductModal from "./EditPpobProductModal"

import "../../style.css";
import { toast } from "react-toastify";



const ProductTab = ({ refresh, handleRefresh,  userInfo }) => {
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState("");
  const [alertModal, setAlertModal] = React.useState("");
  const [showConfirmBulk, setShowConfirmBulk] = React.useState(false);
  const { t } = useTranslation();
  const [multiSelect, setMultiSelect] = React.useState(false);
  const [clearRows, setClearRows] = React.useState(true);
  const [selectedData, setSelectedData] = React.useState([]);

  const [filter, setFilter] = React.useState({
    category: "",
    products: ""
  });



  const [allCategories, setAllCategories] = React.useState([]);


  const [search, setSearch] = React.useState("");

  const [showEditPpobModal, setShowEditPpobModal] = React.useState(false);
  const [hiddenCategory, setHiddenCategory] = React.useState("Inactive");

  const debouncedSearch = useDebounce(search, 500);

  const closeEditPpobModal = () => setShowEditPpobModal(false);

  const inputRef = React.useRef();

  const initialCategory = {
    id: "",
    name: "",
    hidden: "Inactive"
  };

  const handleHiddenCategory = (status) => {
    setHiddenCategory(status);
  };

  // Define the validation schema
const PpobProductSchema = Yup.object().shape({
  category_id: Yup.number().required('Category is required'),
  sub_category_id: Yup.number().nullable(),
  kode: Yup.string().required('Code is required'),
  name: Yup.string().required('Name is required'),
  merchant_cost: Yup.number().required('Merchant cost is required'),
  base_price: Yup.number().required('Base price is required'),
  additional_cost: Yup.number().nullable(),
  admin_cost: Yup.number().required('Admin cost is required'),
  price: Yup.number().required('Price is required'),
  active: Yup.boolean().required('Active status is required')
});

  const formikEditPpobProduct = useFormik({
    initialValues: {
      id: "",
      category_id: "",
      sub_category_id: null,
      kode: "",
      name: "",
      merchant_cost: "",
      base_price: "",
      bassPrice: "",
      additional_cost: null,
      admin_cost: "",
      price: "",
      active: true
    },
    validationSchema: PpobProductSchema,
    onSubmit: async (values) => {
      try {
        const API_URL = process.env.REACT_APP_API_URL;
        const response = await axios.patch(`${API_URL}/api/v1/kios-product/${values.id}`, values);
        console.log('Product updated successfully:', response.data);
        handleRefresh();
        closeEditPpobModal();
        toast.success('Product updated successfully');
      } catch (error) {
        console.error('Error updating product:', error);
        toast.error(error.response?.data?.message || 'Error updating product');
      }
    }
  });


  const enableLoading = () => setLoading(true);
  const disableLoading = () => setLoading(false);


  const showEditPpobProduct = async (data) => {
    console.log("data edit", data);
    setAlertModal("");

    try {
      const API_URL = process.env.REACT_APP_API_URL;
      const response = await axios.get(`${API_URL}/api/v1/kios-product/${data.id}`);
      const productData = response.data.data;

      formikEditPpobProduct.setValues({
        id: productData.id,
        category_id: productData.category_id,
        sub_category_id: productData.sub_category_id,
        kode: productData.kode,
        name: productData.name,
        merchant_cost: productData.merchant_cost,
        base_price: productData.base_price,
        bassPrice: productData.bassPrice,
        additional_cost: productData.additional_cost,
        admin_cost: productData.admin_cost,
        price: productData.price,
        active: productData.active
      });

      // Set hidden category status
      if (productData.active) {
        setHiddenCategory("Active");
      } else {
        setHiddenCategory("Inactive");
      }

      setShowEditPpobModal(true);

      setTimeout(() => {
        if (inputRef.current) inputRef.current.focus();
      }, 100);
    } catch (error) {
      console.error('Error fetching product data:', error);
      setAlertModal("Error fetching product data. Please try again.");
    }
  };



  const [filteredProducts, setFilteredProducts] = React.useState([]);

  const getKiosCategory = async (search, filter) => {
    const API_URL = process.env.REACT_APP_API_URL;
    let filterProductCategory = "";

    if (filter.category) {
      filterProductCategory += `?category_id=${filter.category}`;
    }

    // console.log(`[getKiosCategory] Memulai permintaan dengan filter: ${filterProductCategory}`);

    try {
      const response = await axios.get(
        `${API_URL}/api/v1/kios-product${filterProductCategory}`
      );
      // console.log("[getKiosCategory] Data produk diterima:", response.data);

      // Filter produk berdasarkan input pencarian
      const filtered = response.data.data.filter((product) =>
        product.name.toLowerCase().includes(search.toLowerCase())
      );

      setFilteredProducts(filtered);
      setAllCategories(response.data.data); // Simpan semua data untuk keperluan lain jika diperlukan
    } catch (err) {
      console.error("[getKiosCategory] Error mengambil produk:", err);
      setFilteredProducts([]);
      setAllCategories([]);
    }
  };

  React.useEffect(() => {
    console.log(
      `[useEffect] Memanggil getKiosCategory dengan search: "${debouncedSearch}" dan filter:`,
      filter
    );
    getKiosCategory(debouncedSearch, filter);
  }, [refresh, debouncedSearch, filter]);

  const categoryData = (data) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return [];
    }

    return data.map((item, index) => ({
      id: item.id,
      no: index + 1,
      name: item.name,
      price: item.price,
      base_price: item.base_price,
      additional_cost: item.additional_cost,
      merchant_cost: item.merchant_cost,
      bassPrice: Number(item.base_price || 0) + Number(item.additional_cost || 0),
      products: item.Products ? item.Products.length : 0,
      hidden: item.hidden
    }));
  };

  const customStyles = {
    headRow: {
      style: {
        minHeight: "78px"
      }
    },
    rows: {
      style: {
        minHeight: "70px"
      }
    }
  };

  const columns = [
    {
      name: `${t("no")}`,
      selector: "no",
      sortable: true,
      width: "65px"
    },
    {
      name: `${t("productName")}`,
      selector: "name",
      sortable: true,
      width: "400px",
    },
    // {
    //   name: `${t("basePrice")}`,
    //   selector: "base_price",
    //   sortable: true,
    //   width: "120px",
    // },
    // {
    //   name: `${t("additionalCost")}`,
    //   selector: "additional_cost",
    //   sortable: true,
    //   width: "145px",
    // },
    {
      name: `${t("basePrice")}`,
      selector: "bassPrice",
      sortable: true,
      width: "120px",
    },
    {
      name: `${t("merchantCost")}`,
      selector: "merchant_cost",
      sortable: true,
      width: "140px",
    },
    {
      name: `${t("price")}`,
      selector: "price",
      sortable: true,
      width: "130px",
    },
    {
      name: `${t("actions")}`,
      cell: (rows) => {
        return (
          <Dropdown>
            <Dropdown.Toggle variant="secondary">
              <MoreHoriz color="action" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                onClick={() => showEditPpobProduct(rows)}
              >
                {t("edit")}
              </Dropdown.Item>
              {/* <Dropdown.Item as="button" onClick={() => showConfirmModal(rows)}>
                {t("delete")}
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ];

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    console.log(`[handleSearch] Nilai pencarian diubah: "${searchValue}"`);
    setSearch(searchValue);
  };

  const handleFilter = (e) => {
    const { name, value } = e.target;
    const filterData = { ...filter, [name]: value };
    console.log(`[handleFilter] Filter diubah: ${name} = "${value}"`);
    setFilter(filterData);
  };

  // Tambahkan state untuk saldo
  const [saldo, setSaldo] = React.useState(0);

  // Tambahkan fungsi untuk mengambil data saldo
  const getSaldo = async (businessId) => {
    try {
      const API_URL = process.env.REACT_APP_API_URL;
      const response = await axios.get(`${API_URL}/api/v1/kios-transaction/check-saldo/${businessId}`);
      setSaldo(response.data.data.saldo);
    } catch (err) {
      console.error("Error fetching saldo:", err);
      setSaldo(0);
    }
  };

  // Panggil getSaldo saat komponen dimount dan saat refresh
  React.useEffect(() => {
    if (userInfo?.business_id) {
      getSaldo(userInfo.business_id);
    }
  }, [refresh, userInfo]);

  return (
    <Row>
      {/* <ConfirmModal
        title={t("deleteProductCategory")}
        body={t("areYouSureWantToDelete?")}
        buttonColor="danger"
        handleClick={handleDelete}
        state={showConfirm}
        closeModal={closeConfirmModal}
        loading={loading}
      /> */}

      {/* <ProductCategoryModal
        t={t}
        hiddenCategory={hiddenCategory}
        handleHiddenCategory={handleHiddenCategory}
        state={showAddCategory}
        closeModal={closeAddCategoryModal}
        loading={loading}
        alert={alertModal}
        title={t("addProductCategory")}
        formikCategory={formikAddCategory}
        inputRef={inputRef}
      /> */}

      <EditPpobProductModal
        t={t}
        hiddenCategory={hiddenCategory}
        handleHiddenCategory={handleHiddenCategory}
        state={showEditPpobModal}
        closeModal={closeEditPpobModal}
        loading={loading}
        alert={alertModal}
        title={t("editProduct")}
        formikPpobProduct={formikEditPpobProduct}
        inputRef={inputRef}
      />

      <ConfirmModal
        title={`${t("delete")} ${selectedData.length} ${t(
          "selectedModifiers"
        )}`}
        body={t("areYouSureWantToDelete?")}
        buttonColor="danger"
        // handleClick={() => handleBulkDelete(selectedData)}
        state={showConfirmBulk}
        // closeModal={closeConfirmBulkModal}
        loading={loading}
      />

      <Col md={12} style={{ minHeight: "100%" }}>
        {alert ? <Alert variant="danger">{alert}</Alert> : ""}

        <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
          <div className="headerPage" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="headerStart" style={{ flex: 1 }}>
              {!selectedData.length ? (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <h3>{t("productTitle")}</h3>
                  </div>
                  <div className="bg-light rounded p-3 mr-3">
                    <h3 style={{ color: "grey", fontSize: "12px", fontWeight: "bold" }}>
                      Saldo Saat Ini
                    </h3>
                    <h3 style={{ color: "green", fontSize: "14px" }}>
                      Rp {new Intl.NumberFormat('id-ID').format(saldo)}
                    </h3>
                  </div>
                </div>
              ) : (
                <h3>
                  {selectedData.length}
                  {t("itemSelected")}
                </h3>
              )}
            </div>
          </div>

          <div className="filterSection lineBottom">
            <Row>
              <Col>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text style={{ background: "transparent" }}>
                      <Search />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    placeholder={t("search")}
                    value={search}
                    onChange={handleSearch}
                  />
                </InputGroup>
              </Col>

              <Col>
                <Row>
                  <Col>
                    <Form.Group as={Row}>
                      <Form.Label
                        style={{ alignSelf: "center", marginBottom: "0" }}
                      >
                        {t("product")}
                      </Form.Label>
                      <Col>
                        <Form.Control
                          as="select"
                          name="category"
                          value={filter.category}
                          onChange={handleFilter}
                        >
                          <option value="">{t("all")}</option>
                          {allCategories.map((item) => {
                            return (
                              <option key={item.id} value={item.id} disabled={true}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <DataTable
            noHeader
            pagination
            columns={columns}
            data={categoryData(filteredProducts)}
            style={{ minHeight: "100%" }}
            selectableRows={multiSelect}
            // onSelectedRowsChange={handleSelected}
            clearSelectedRows={clearRows}
            noDataComponent={t("thereAreNoRecordsToDisplay")}
            customStyles={customStyles}
          />
        </Paper>
      </Col>
    </Row>
  );
};

export default ProductTab;
